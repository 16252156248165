<template>
  <div
    class="association-words"
  >
    <div class="association-half">
      <!-- correction -->
      <div
        v-if="correctionNum"
        class="association-words-item"
        :class="[
          'correction',
          {
            active: itemActiveIndex == 0,
          },
        ]"
        @click="
          event =>
            goSearchPage(event, {
              keyword: correction,
              result_type: 8,
              position: 1,
            })
        "
        @mouseenter="handleCorrectionMouseEnter"
        @mouseleave="handleCorrectionMouseLeave"
      >
        <Icon
          name="sui_icon_search_black_14px"
          size="14px"
          class="popular-search-icon"
        />
        <span
          :class="active"
        >{{ language.SHEIN_KEY_PC_16870 }}: </span>{{ correction }}
      </div>
      <!-- popular -->
      <div
        v-for="(item, index) in associationWords.word"
        :key="'popular' + index"
        class="association-words-item"
        :class="{
          active: itemActiveIndex == correctionNum + index,
          'has-img': !!item.imgUrl
        }"
        @click="
          event =>
            goSearchPage(event, {
              wordOriginRecord: {
                word_id: item.word_id,
                trace_id: item.trace_id,
              },
              keyword: item.keywords,
              result_type: correctionNum ? 8 : 4,
              position: correctionNum + index + 1,
              tag: item.tag,
              wordTagType: item?.wordTagType,
              wordExtraReport: {
                hasImg: item.imgUrl ? 1 : 0
              }
            })
        "
        @mouseenter="handlePopularMouseEnter(index)"
        @mouseleave="handlePopularMouseLeave"
      >
        <Icon
          name="sui_icon_search_black_14px"
          size="14px"
          class="popular-search-icon"
          :style="{display: !item.imgUrl ? '' : 'none !important' }"
        />
        <img
          v-show="item.imgUrl"
          class="lazyload popular-search-img"
          :class="{
            'visible': !!item.imgUrl
          }"
          :data-src="item.imgUrl"
          data-design-width="32"
          width="32"
          height="32"
          @error="handleImageError(item)"
        />
        <div
          class="association-item-main"
          v-html="item.transformed">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from '@shein-aidc/icon-vue3'

export default {
  name: 'AssociationNew',
  components: {
    Icon,
  },
  props: {
    correctionNum: {
      type: Number,
      default: 0,
    },
    popularNum: {
      type: Number,
      default: 0,
    },
    correction: {
      type: String,
      default: '',
    },
    associationWords: {
      type: Object,
      default: () => ({}),
    },
    itemActiveIndex: {
      type: Number,
      default: -1,
    },
    language: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['goSearchPage', 'onItemActiveIndex'],
  data() {
    return {
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  unmounted() {},
  methods: {
    handlePopularMouseEnter(index){
      const itemActiveIndex = this.correctionNum + index
      this.$emit('onItemActiveIndex', itemActiveIndex)
    },
    handlePopularMouseLeave(){
      const itemActiveIndex = -1
      this.$emit('onItemActiveIndex', itemActiveIndex)
    },
    handleCorrectionMouseEnter() {
      const itemActiveIndex = 0
      this.$emit('onItemActiveIndex', itemActiveIndex)
    },
    handleCorrectionMouseLeave() {
      const itemActiveIndex = -1
      this.$emit('onItemActiveIndex', itemActiveIndex)
    },
    goSearchPage(event, params) {
      this.$emit('goSearchPage', {
        event,
        ...params,
      })
    },
    handleImageError(associationItem) {
      associationItem.imgUrl = ''
    },
  },
}
</script>

<style lang="less">
.association-words {
  padding: 8px 0 7px;
}
.association-words-title {
  padding: 18px 0 12px 10px;
  font-size: 14px;
  font-weight: bold;
  /* rw:begin */
  font-family: 'Adieu';
}

.association-words-item {
  padding: 0 10px 0 4px;
  display: flex;
  position: relative;
  line-height: 27px;
  white-space: nowrap;
  overflow: hidden;
  font-size: 13px;
  font-family: Arial;
  cursor: pointer;
  text-overflow: ellipsis;
  color: #000;
  font-weight: 700;
  /* rw:begin */
  color: #fc4070;
  span.match-text {
    font-weight: 400;
    color: #767676;
  }
  &.active {
    background-color: #edeef0;
    /* rw:begin */
    background-color: #fff1f8;
  }
  &.has-img {
    line-height: 36px;
  }

  &.correction {
    padding: 0 8px 0 4px;
    line-height: 27px;
    white-space: normal;
    /* ! 这里需要注意：纠错词的active是【did you mean:】 而不是 匹配到的词所以样式是反过来的 */
    color: #222222;
    font-weight: normal;
    font-size: 13px;
    font-weight: 700;
    font-family: Arial;
    /* rw:begin */
    color: #fc4070;
    span.active {
      color: #666;
      font-size: 13px;
      font-weight: 400;
      font-family: Arial;
    }
  }
}

.association-item-main {
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
}
.popular-search-icon {
  display: flex !important; /* stylelint-disable-line declaration-no-important */
  align-items: center;
  margin: 0 13px 0 9px;
  color: #000 !important; /* stylelint-disable-line declaration-no-important */
}
.popular-search-img {
  object-fit: cover;
  border-radius: 4px;
  border: 0.5px solid rgba(215, 215, 215, 0.40);
  margin-right: 4px;
  &.visible {
    margin-top: 2px;
    margin-bottom: 2px;
  }
}
.association-item-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.association-hk-tag {
  margin-left: 4px;
  padding: 0 16px 0 4px;
  border: 1px solid;
  color: #9462ff;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  border-radius: 2px;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    right: 2px;
    top: 2px;
    width: 12px;
    height: 12px;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url('https://img.ltwebstatic.com/images3_ccc/2024/05/16/1c/1715862428b44c046d259ee6df9c1ef75c77525b27.png');
  }
}
.association-choice-tag {
  margin-left: 4px;
}

.tag-icon {
  min-width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-size: contain;
  transform-origin: center;
  transform: scale(1.25);
  background-image: url('https://img.shein.com/images3/2023/10/27/f5/1698394226527e3792513f865e0b2d6124c9e7a646.png');
}
.association-tag-wrapper {
  margin-left: 12px;
  margin-right: 8px;
  flex: 1;
  text-align: right;
  color: #222;
  font-size: 12px;
  font-weight: 400;
  .association-tag-item {
    background: #F6F6F6;
    border-radius: 30px;
    margin-right: 8px;
    padding: 0px 8px;
    height: 20px;
    line-height: 20px;
    display: inline-block;
    &.hidden {
      position: absolute;
      opacity: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
@media (max-width: 920px) {
  .association-words {
    flex-direction: column;
    padding: 8px 0px 7px 4px;
  }
}
@media (max-height: 639px) and (min-height: 519px) {
  .association-words {
    padding-right: 4px;
  }
}
@media (max-height: 519px) {
  .association-words {
    padding-right: 4px;
  }
}
</style>
